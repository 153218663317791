import { API_URL_WS_FORMULAIRE } from '@amo/core/constants/constants.js';
import { encrypt } from '@amo/core/utils/functions';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { change, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import { postFill } from '../../../redux/actions/app/fill/fill.actions';
import { SAVE_DEVIS, postSaveDevis } from '../../../redux/actions/app/saveDevis/saveDevis.actions';
import { setIndexQuestion, setStep } from '../../../redux/actions/app/ui/ui.actions';
import { setLocalStorage } from '../../../redux/actions/core/localStorage/localStorage.actions';
import {
    getActeGestionDemandeTarifInit,
    getApporteur1Init,
    getCanalDemandeTarifInit,
    getDateMECVehiculeDemandeTarif,
    getHasSinistreWithEmptyNatureDemandeTarifInit,
    getIBANDemandeContratInit,
    getImmatriculationVehiculeDemandeTarifInit,
    getNameWhitelabel,
    getQuestionInit,
    getSource,
    getStatutContratDemandeContratInit,
    getStepInit,
    getToken,
    getValeurVehiculeVehiculeDemandeTarifInit,
    getFormuleChoisiePoliceDemandeTarifInit,
    getDateSituationContratDevisInit,
    getDataCodeProduitDevisInit,
    getFidDemandeTarifInit,
    getDataDateEffetContratDevisInit, getHeureEffetContratDevisInit
} from '../../../redux/selectors/init/init.selectors';
import {
    getErrorEntity,
    getIndexQuestion,
    getLoadingEntity,
    getStep,
    getUtmSource,
} from '../../../redux/selectors/ui/ui.selectors';
import { getVehicule } from '../../../redux/selectors/vehicule/vehicule.selectors';
import { getStepBySlug } from '../../../utils/function';
import Init from './Init';
import { InitialValues } from './InitialValues';
import formatDataBeforeStringify from './formatDataBeforeStringify';

const mapStateToProps = (state) => {
    return {
        step: getStep(state),
        indexQuestion: getIndexQuestion(state),
        stepInit: getStepInit(state),
        questionInit: getQuestionInit(state),
        data: getFormValues('devis-avenant')(state),
        errorsForm: getFormSyncErrors('devis-avenant')(state),
        queryFill: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        queryTarif: `${API_URL_WS_FORMULAIRE}/get_tarif/${getToken(state)}`,
        saveDevisLoading: getLoadingEntity(state, SAVE_DEVIS),
        saveDevisError: getErrorEntity(state, SAVE_DEVIS),
        vehicule: getVehicule(state),
        initialValues: InitialValues(state),
        nameWhitelabel: getNameWhitelabel(state),
        utm_source: getUtmSource(state),
        token: getToken(state),
        canal: getCanalDemandeTarifInit(state),
        apporteur1: getApporteur1Init(state),
        hasFid: getFidDemandeTarifInit(state),
        isAvenant: getActeGestionDemandeTarifInit(state) === 'AV',
        isMAJDec: getStatutContratDemandeContratInit(state) === '2',
        isRemiseVigueur: getStatutContratDemandeContratInit(state) === '5',
        source: getSource(state),
        dateMEC: getDateMECVehiculeDemandeTarif(state),
        iban: getIBANDemandeContratInit(state),
        immat: getImmatriculationVehiculeDemandeTarifInit(state),
        valeurVehicule: getValeurVehiculeVehiculeDemandeTarifInit(state),
        hasEmptyNatureSinistre: getHasSinistreWithEmptyNatureDemandeTarifInit(state),
        formuleByDefault: getFormuleChoisiePoliceDemandeTarifInit(state),
        dateSituationContrat: getDateSituationContratDevisInit(state),
        dateEffetContrat: getDataDateEffetContratDevisInit(state),
        heureEffetContrat: getHeureEffetContratDevisInit(state),
        codeProduit: getDataCodeProduitDevisInit(state)
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            postFill,
            setIndexQuestion,
            setStep,
            setLocalStorage,
            postSaveDevis,
            changeValue: (field, value) => change('devis-avenant', field, value),
        },
        dispatch,
    );

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { data, indexQuestion, step, queryFill, queryTarif } = stateProps;
    const { postFill, setIndexQuestion, setStep, setLocalStorage, changeValue } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        nextQuestion: (delais) => {
            let storage = encrypt(
                JSON.stringify({ step, question: indexQuestion, data: formatDataBeforeStringify(data) }),
            );
            setLocalStorage({ entity: 'NEXT_QUESTION', storage, name: 'estimate' });
            if (indexQuestion < document.getElementsByClassName('question').length - 1) {
                if (delais) {
                    setTimeout(() => {
                        setIndexQuestion({ index: indexQuestion + 1 });
                    }, delais);
                } else {
                    setIndexQuestion({ index: indexQuestion + 1 });
                }
            } else {
                setStep({ step: step + 1 });
                setIndexQuestion({ index: 0 });
            }
            window.scroll(0, 0);
        },
        previousQuestion: () => {
            /* reset la question en cours si click sur le btn retour uniquement avant l'étape tarif */
            if (step < getStepBySlug('votre-tarif')) {
                const questionElements = document.getElementsByClassName('question');
                if (questionElements[indexQuestion]) {
                    const inputs = questionElements[indexQuestion].getElementsByTagName('input');
                    for (let i = 0; i < inputs.length; i++) {
                        changeValue(inputs[i].getAttribute('name'), null);
                    }
                }
            }

            if (indexQuestion > 0) {
                setIndexQuestion({ index: indexQuestion - 1 });
            } else {
                setStep({ step: step - 1 });
                setTimeout(() => {
                    const questionElements = document.getElementsByClassName('question');
                    if (questionElements.length > 0) {
                        setIndexQuestion({ index: questionElements.length - 1 });
                    }
                }, 15);
            }
            window.scroll(0, 0);
        },
        goTo: ({ step, question }) => {
            setStep({ step });
            setIndexQuestion({ index: question });
        },
        postFill: ({ delais, getTarif, codePromo }) => {
            let storage = encrypt(JSON.stringify({ step, question: indexQuestion, data: data }));
            setLocalStorage({ entity: 'NEXT_QUESTION', storage, name: 'estimate' });

            if (delais) {
                setTimeout(() => {
                    postFill({ step, form: data, query: queryFill, queryTarif, getTarif, codePromo });
                }, delais);
            } else {
                postFill({ step, form: data, query: queryFill, queryTarif, getTarif, codePromo });
            }
        },
    };
};

const InitContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'devis-avenant',
    }),
)(Init);

export default InitContainer;
